.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  font-family: Cairo;
}
body{
  /* background-color: #d9d9d9; */
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

li {
  display: inline;
  padding:10px;
}